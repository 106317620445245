<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-2xl font-bold mr-auto">帳務管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <!-- <button class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增帳務
        </button> -->
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows">
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-3 mt-2 xl:mt-0 ">
            <vxe-select
              v-model="gridQueryParams.source"
              clearable
              placeholder="請選擇來源"
              class="mr-2"
            >
              <vxe-option
                v-for="item in Object.values($enums.OrderSource)"
                :key="'source-' + item.Value"
                :value="item.Value"
                :label="item.Name"
              >
              </vxe-option>
            </vxe-select>
            <vxe-select
              v-model="gridQueryParams.status"
              clearable
              placeholder="請選擇狀態"
              class="mr-2"
            >
              <vxe-option
                v-for="item in Object.values($enums.OrderStatus)"
                :key="'status-' + item.Value"
                :value="item.Value"
                :label="item.Name"
              >
              </vxe-option>
            </vxe-select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <vxe-input type="search" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" clearable="true" @keyup="
              (e) => {
                if (e.$event.keyCode === 13) grid.refresh();
              }
            " @clear="grid.refresh()" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" @edit="onGridEdit" v-bind="gridOptions">
          <template #modal="{ row, submit, reset }">
            <vxe-form ref="form" :title-width="100" title-align="right" v-bind="formOptions" :data="row" @reset="reset"
              @submit="submit">
              <template #column-records="{ data }">
                <VxeTable ref="recordsGrid" :data="data?.Records" v-bind="recordsGridOptions.config">
                  <VxeColumn field="Time" title="繳費時間" resizable :edit-render="{}"
                    :formatter="({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD HH:mm:ss')">
                    <template #edit="{ row }">
                      <vxe-input type="datetime" v-model="row.Time" transfer />
                    </template>
                  </VxeColumn>
                  <VxeColumn field="Type" title="行為類型" resizable :edit-render="{}"
                    :formatter="({ cellValue }) => Object.values($enums.PaymentActionType).find(e => e.Value === cellValue)?.Name">
                    <template #edit="{ row }">
                      <vxe-select v-model="row.Type" transfer>
                        <vxe-option v-for="(item, index) in Object.values($enums.PaymentActionType)"
                          :key="'payment-action-type-' + index" :label="item.Name" :value="item.Value"></vxe-option>
                      </vxe-select>
                    </template>
                  </VxeColumn>
                  <VxeColumn field="Success" title="是否成功" resizable :edit-render="{}"
                    :formatter="({ cellValue }) => cellValue ? '是' : '否'">
                    <template #edit="{ row }">
                      <vxe-switch v-model="row.Success" openLabel="是" closeLabel="否" />
                    </template>
                  </VxeColumn>
                  <!-- <VxeColumn title="操作" width="160" resizable>
                    <template #header>
                      <vxe-button @click="recordsGridOptions.insert">新增
                      </vxe-button>
                    </template>
                    <template #default="{ row }">
                      <template v-if="recordsGrid.isActiveByRow(row)">
                        <vxe-button @click="recordsGridOptions.save(row)">確認</vxe-button>
                        <vxe-button @click="recordsGridOptions.cancel(row)">取消</vxe-button>
                      </template>
                      <template v-else>
                        <vxe-button @click="recordsGridOptions.edit(row)">編輯</vxe-button>
                        <vxe-button @click="recordsGridOptions.delete(row)">刪除</vxe-button>
                      </template>
                    </template>
                  </VxeColumn> -->
                </VxeTable>
              </template>
            </vxe-form>
          </template>
          <template #modal-footer v-if="grid?.editingRow?.BeforeStatus === $enums.PaymentStatus.Confirming.Value">
            <vxe-button type="submit" status="primary" content="確認" @click="onFormSubmit">
            </vxe-button>
            <!-- <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button> -->
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps, VxeTableProps } from "vxe-table";
interface QueryParams {
  source: number | null;
  status: number | null;
}

function queryToCondition(condition: Condition, query: QueryParams): Condition {
  if (query.source != null) {
    condition.and("Order.OrderSource", Operator.Equal, query.source);
  }
  if (query.status != null) {
    condition.and("Status", Operator.Equal, query.status);
  }
  return condition;
}

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});
    const gridQueryParams = reactive<QueryParams>({
      source: null,
      status: null,
    });
    const gridOptions: GridOptions = {
      title: "帳務",
      canCreate: false,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        { field: "CreatedTime", title: "建立時間", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD HH:mm:ss') },
        { field: "OrderNumber", title: "訂單編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: "Type",
          title: "繳費方式",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.PaymentType).find(e => e.Value === cellValue)?.Name : undefined
        },
        {
          field: "Order.OrderSource",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderSource).find(e => e.Value === cellValue)?.Name : undefined
        },
        { field: "Amount", title: "金額", showHeaderOverflow: true, showOverflow: true, sortable: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        {
          field: "Status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.PaymentStatus).find(e => e.Value === cellValue)?.Name : undefined
        },
      ],
      promises: {
        query: model
          ? (params) => {
            return new Promise(resolve => {
              if (params?.sortings?.length === 0) {
                params.sortings.push({ column: 'CreatedTime', order: 1 });
              }
              params.condition = queryToCondition(params.condition!, gridQueryParams);
              model.dispatch('payment/query', params).then(async res => {
                const orders = await model.dispatch("order/query", { condition: new Condition("Id", Operator.In, res.data.map((e: any) => e.OrderId)) });
                res.data.forEach((e: any) => {
                  const order = orders.find((o: any) => o.Id === e.OrderId);
                  e.OrderNumber = order.Number;
                })
                resolve(res);
              })
            });
          }
          : undefined,
        queryAll: model ? () => model.dispatch('payment/query') : undefined,
        save: model
          ? (params) => model.dispatch('payment/save', params)
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      items: [
        { field: "OrderNumber", title: "訂單編號", span: 12, itemRender: { name: "$input", props: { disabled: true } } },
        { field: "Type", title: "繳費方式", span: 12, itemRender: { name: "$select", props: { placeholder: "類型" }, options: model ? Object.values(model.enums.PaymentType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", props: { placeholder: "類型" }, options: model ? Object.values(model.enums.PaymentStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "Amount", title: "金額", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入金額", disabled: true } } },
        { field: "Remark", title: "備註", span: 24, itemRender: { name: "$textarea", props: { placeholder: "請輸入備註" } } },
        { field: "Records", title: "繳費紀錄", span: 24, slots: { default: "column-records" } },
      ],
      rules: {
        Type: [{ required: true }],
        Status: [{ required: true }],
        Amount: [{ required: true }],
      }
    };

    const recordsGrid = ref<any>({});
    const recordsGridOptions = {
      config: {
        autoResize: true,
        keepSource: true,
        showFooter: true,
        editConfig: { trigger: 'manual', mode: 'row', autoClear: false },
        editRules: {
          Time: [{ required: true, message: '必要欄位' }],
          Type: [{ required: true, message: '必要欄位' }],
          Success: [{ required: true, message: '必要欄位' }]
        }
      } as VxeTableProps
    }

    return {
      grid,
      gridOptions,
      formOptions,
      recordsGrid,
      gridQueryParams,
      recordsGridOptions
    };
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      if (row.Id) {
        const entity = await this.$model.dispatch("payment/find", row.Id);
        Object.assign(row, entity);
      }
      row.BeforeStatus = row.Status;
      callback();
    },
    onFormSubmit() {
      const grid = (this.$refs as any).grid;
      if (grid.editingRow.Status === (this.$enums.PaymentStatus as any).Confirmed.Value && !confirm('款項確認後，即無法變更，確認變更?')) {
        return;
      }
      const form = (this.$refs as any).form;
      form.validate((errMap: any) => {
        if (errMap === undefined) form.dispatchEvent('submit');
      })
    }
  }
});
</script>
