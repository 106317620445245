
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps, VxeTableProps } from "vxe-table";
interface QueryParams {
  source: number | null;
  status: number | null;
}

function queryToCondition(condition: Condition, query: QueryParams): Condition {
  if (query.source != null) {
    condition.and("Order.OrderSource", Operator.Equal, query.source);
  }
  if (query.status != null) {
    condition.and("Status", Operator.Equal, query.status);
  }
  return condition;
}

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});
    const gridQueryParams = reactive<QueryParams>({
      source: null,
      status: null,
    });
    const gridOptions: GridOptions = {
      title: "帳務",
      canCreate: false,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        { field: "CreatedTime", title: "建立時間", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD HH:mm:ss') },
        { field: "OrderNumber", title: "訂單編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: "Type",
          title: "繳費方式",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.PaymentType).find(e => e.Value === cellValue)?.Name : undefined
        },
        {
          field: "Order.OrderSource",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderSource).find(e => e.Value === cellValue)?.Name : undefined
        },
        { field: "Amount", title: "金額", showHeaderOverflow: true, showOverflow: true, sortable: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        {
          field: "Status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.PaymentStatus).find(e => e.Value === cellValue)?.Name : undefined
        },
      ],
      promises: {
        query: model
          ? (params) => {
            return new Promise(resolve => {
              if (params?.sortings?.length === 0) {
                params.sortings.push({ column: 'CreatedTime', order: 1 });
              }
              params.condition = queryToCondition(params.condition!, gridQueryParams);
              model.dispatch('payment/query', params).then(async res => {
                const orders = await model.dispatch("order/query", { condition: new Condition("Id", Operator.In, res.data.map((e: any) => e.OrderId)) });
                res.data.forEach((e: any) => {
                  const order = orders.find((o: any) => o.Id === e.OrderId);
                  e.OrderNumber = order.Number;
                })
                resolve(res);
              })
            });
          }
          : undefined,
        queryAll: model ? () => model.dispatch('payment/query') : undefined,
        save: model
          ? (params) => model.dispatch('payment/save', params)
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      items: [
        { field: "OrderNumber", title: "訂單編號", span: 12, itemRender: { name: "$input", props: { disabled: true } } },
        { field: "Type", title: "繳費方式", span: 12, itemRender: { name: "$select", props: { placeholder: "類型" }, options: model ? Object.values(model.enums.PaymentType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", props: { placeholder: "類型" }, options: model ? Object.values(model.enums.PaymentStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "Amount", title: "金額", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入金額", disabled: true } } },
        { field: "Remark", title: "備註", span: 24, itemRender: { name: "$textarea", props: { placeholder: "請輸入備註" } } },
        { field: "Records", title: "繳費紀錄", span: 24, slots: { default: "column-records" } },
      ],
      rules: {
        Type: [{ required: true }],
        Status: [{ required: true }],
        Amount: [{ required: true }],
      }
    };

    const recordsGrid = ref<any>({});
    const recordsGridOptions = {
      config: {
        autoResize: true,
        keepSource: true,
        showFooter: true,
        editConfig: { trigger: 'manual', mode: 'row', autoClear: false },
        editRules: {
          Time: [{ required: true, message: '必要欄位' }],
          Type: [{ required: true, message: '必要欄位' }],
          Success: [{ required: true, message: '必要欄位' }]
        }
      } as VxeTableProps
    }

    return {
      grid,
      gridOptions,
      formOptions,
      recordsGrid,
      gridQueryParams,
      recordsGridOptions
    };
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      if (row.Id) {
        const entity = await this.$model.dispatch("payment/find", row.Id);
        Object.assign(row, entity);
      }
      row.BeforeStatus = row.Status;
      callback();
    },
    onFormSubmit() {
      const grid = (this.$refs as any).grid;
      if (grid.editingRow.Status === (this.$enums.PaymentStatus as any).Confirmed.Value && !confirm('款項確認後，即無法變更，確認變更?')) {
        return;
      }
      const form = (this.$refs as any).form;
      form.validate((errMap: any) => {
        if (errMap === undefined) form.dispatchEvent('submit');
      })
    }
  }
});
